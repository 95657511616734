import React, { useState } from "react"
import { useSwipeable } from "react-swipeable"
import { graphql } from "gatsby"
import css from "@emotion/css"
import { Helmet } from "react-helmet"

export default function ThisIsShpock({ data: { page } }) {
  const [active, setActive] = useState(0)
  const handlers = useSwipeable({
    onSwipedLeft: e => {
      setActive(a => {
        if (a === 0) {
          return page.carousel.length - 1
        } else {
          return a - 1
        }
      })
    },
    onSwipedRight: e => {
      setActive(a => {
        if (a === page.carousel.length - 1) {
          return 0
        } else {
          return a + 1
        }
      })
    },
    delta: 10, // min distance(px) before a swipe starts
    preventDefaultTouchmoveEvent: false, // preventDefault on touchmove, *See Details*
    trackTouch: true, // track touch input
    trackMouse: false, // track mouse input
    rotationAngle: 0, // set a rotation angle
  })
  return (
    <>
      <Helmet>
        <title>This is Shpock</title>
      </Helmet>
      <div className="container">
        <div className="carousel">
          <ul
            {...handlers}
            className="slides"
            style={{
              touchAction: "none",
              userSelect: "none",
              WebkitUserDrag: "none",
              WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
            }}
          >
            {page.carousel.map((slide, i) => {
              return (
                <li
                  ke={i}
                  style={{
                    background: `url(${slide.image.fixed.src})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                  className={active === i ? "slide active" : "slide"}
                ></li>
              )
            })}
            <li
              className="slide__prev"
              css={css`
                list-style: none;
              `}
              onClick={e => {
                setActive(a => {
                  if (a === 0) {
                    return page.carousel.length - 1
                  } else {
                    return a - 1
                  }
                })
              }}
            />
            <li
              className="slide__next"
              css={css`
                list-style: none;
              `}
              onClick={e => {
                setActive(a => {
                  if (a === page.carousel.length - 1) {
                    return 0
                  } else {
                    return a + 1
                  }
                })
              }}
            />
          </ul>
          <ul className="bullets">
            {page.carousel.map((slide, i) => (
              <li
                key={i}
                onClick={e => {
                  setActive(i)
                }}
                className={`bullet${active === i ? " active" : ""}`}
              />
            ))}
          </ul>
        </div>
        <h2 className="h1 my-4 text-center">{page.headerTitle}</h2>
        <div
          className="text-block"
          dangerouslySetInnerHTML={{ __html: page.textBlock1.md.html }}
        />
        <div
          className="text-block"
          dangerouslySetInnerHTML={{ __html: page.textBlock2.md.html }}
        />
        <div
          className="text-block"
          dangerouslySetInnerHTML={{ __html: page.textBlock4.md.html }}
        />
      </div>
    </>
  )
}
export const query = graphql`
  {
    page: contentfulShpockPage {
      carousel {
        overlay {
          md: childMarkdownRemark {
            html
          }
        }
        image: largeImage {
          fixed(width: 1920) {
            src
          }
        }
      }
      headerTitle
      title2
      textBlock1 {
        md: childMarkdownRemark {
          html
        }
      }
      textBlock2 {
        md: childMarkdownRemark {
          html
        }
      }
      textBlock3 {
        md: childMarkdownRemark {
          html
        }
      }
      textBlock4 {
        md: childMarkdownRemark {
          html
        }
      }
    }
  }
`
